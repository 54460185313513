import axios from 'axios';
import { PATH, ADMIN_URL } from '../../Constants';
import * as documentActionType from './documentActionType';
import { toast } from 'react-toastify';
import { redirectToLogin } from '../Common/CommonAction';

var token = sessionStorage.getItem('token');
var admin_email = sessionStorage.getItem('name');

export const getDocumentData = (page_no = 1, rows_per_page = 10) => async (dispatch, getState) => {
  const { start, end, total } = getState().common.pagination;
  console.log('getstate', getState());
  // dispatch({
  //   type: documentActionType.DOCUMENT_DATA,
  //   payload: [],
  // });
  const data1 ={
    page_no,
    rows_per_page,
  };
  const args = {
    method: 'POST',
    url: `${ADMIN_URL}${PATH.display_document}`,
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: data1,
  };

  try {
    let api = await axios(args);
    if (api.status == 200) {
      let data = [];
      api.data.result.Document_Types.map((e) => {
        data.push([
          e.name,
          e.queues_count,
          // e.mapping.hasOwnProperty(queueName) ? 'Yes' : 'No',
          {
            mappings: Object.entries(e.queues_mapped)
              .filter((f) => f[1] === 'Yes')
              .flat()
              .filter((g) => g !== 'Yes'),
          },
          e.fields_count,
          e.id,
          e.field_ids,
          e.field_names,
        ]);
      });
      // setDisplayProcessor(data);
      sessionStorage.setItem('total_count',api.data.result.Total_Document_Types);
      dispatch({
        type: documentActionType.DOCUMENT_DATA,
        payload: data,
      });
      dispatch({
        type: documentActionType.DOCUMENT_DATA_SIZE,
        payload: api.data.result.Total_Document_Types,
      });

      console.log(data);
    } else {
      toast.error('Something Went Wrong');
      dispatch({
        type: documentActionType.DOCUMENT_DATA,
        payload: [],
      });
    }
  } catch (error) {
    if (error && error.response) {
      dispatch({
        type: documentActionType.DOCUMENT_DATA,
        payload: [],
      });
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

export const setDocumentData = (fields) => async (dispatch) => {
  dispatch({
    type: documentActionType.ADD_DOCUMENT,
    payload: fields,
  });
};

export const addDocumentData = (fields) => async (dispatch) => {
  const args = {
    method: 'post',
    url: `${ADMIN_URL}${PATH.add_document}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: fields,
  };
  console.log('check add processor,', args);
  try {
    let api = await axios(args);
    if (api.status == 200) {
      toast.success(api?.data?.result?.message);
      return true;
    } else {
      toast.error('Something Went Wrong');
      return false;
    }
    //  console.log(api,"status api")
  } catch (error) {
    if (error && error.response) {
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
export const deleteDocuments = (fields) => async (dispatch) => {
  const args = {
    method: 'post',
    url: `${ADMIN_URL}${PATH.delete_document}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: fields,
  };
  console.log('check add processor,', args);
  try {
    let api = await axios(args);
    if (api.status == 200) {
      toast.success(api?.data?.result?.message);
      return true;
    } else {
      toast.error('Something Went Wrong');
      return false;
    }
    //  console.log(api,"status api")
  } catch (error) {
    if (error && error.response) {
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

export const mapDocumentsAction = (index) => async (dispatch) => {
  const args = {
    method: 'post',
    url: `${ADMIN_URL}${PATH.map_document}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: index,
  };
  console.log('check add processor,', args);
  try {
    let api = await axios(args);
    if (api.status === 200) {
      toast.success(api?.data?.message);
      return true;
    } else {
      toast.error('Something Went Wrong');
      return false;
    }
    //  console.log(api,"status api")
  } catch (error) {
    if (error && error.response) {
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
// * UNMAP DOCUMENT
export const unMapDocumentsAction = (data) => async (dispatch) => {
  const args = {
    method: 'post',
    url: `${ADMIN_URL}${PATH.unmap_document}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: data,
  };
  console.log('check add processor,', args);
  try {
    let api = await axios(args);
    if (api.status === 200) {
      toast.success(api?.data?.message);
      return true;
    } else {
      toast.error('Something Went Wrong');
      return false;
    }
    //  console.log(api,"status api")
  } catch (error) {
    if (error && error.response) {
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
// Get fields for document
export const getFieldsForDocument = () => async (dispatch) => {
  const args = {
    method: 'GET',
    url: `${ADMIN_URL}${PATH.fields_document}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v3.0',
    },
  };

  try {
    let api = await axios(args);
    if (api.status == 200) {
      console.log('check Api', api);
      dispatch({
        type: documentActionType.GET_FIELDS_FOR_DOCUMENT,
        payload: api.data,
      });
    } else {
      toast.error('Something Went Wrong');
      dispatch({
        type: documentActionType.GET_FIELDS_FOR_DOCUMENT,
        payload: {},
      });
    }
  } catch (error) {
    if (error && error.response) {
      dispatch({
        type: documentActionType.GET_FIELDS_FOR_DOCUMENT,
        payload: {},
      });
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

// * Edit/Update Document
export const updateDocument = (data) => async (dispatch) => {
  const args = {
    method: 'PATCH',
    url: `${ADMIN_URL}${PATH.edit_document}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: data,
  };
  try {
    let api = await axios(args);
    if (api.status === 200) {
      toast.success(api?.data?.message);
      return true;
    } else {
      toast.error(api.data.message);
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
// setUpdateFieldsForDocument
export const setUpdateFieldsForDocument = (data) => async (dispatch) => {
  dispatch({
    type: documentActionType.SET_UPDATE_FIELDS_FOR_DOCUMENT,
    payload: data,
  });
};
