import React, { useState, useEffect } from 'react';
import './SideNavbar.css';
import Modal from '../Modal/Modal';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Tooltip from '@material-ui/core/Tooltip';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import InfoImage from '../../Images/info.svg';
import devInfo from '../../Images/devinfo.svg';
import devhub from '../../Images/devhub.svg';
import { handleResetSearchTerm } from '../../Store/User/userAction';
import { useDispatch } from 'react-redux';
import ReportModal from '../Modal/ReportModal';
import { resetPreProcessing } from '../../Store/PreProcessing/PreprocessingActions';

const SideMenu = ({ processorModule }) => {
  const location = useLocation();
  const navigate = useHistory();
  const [state, setState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showUserInfoModal, setShowUserInfoModal] = useState(false);
  const [info, setInfo] = useState(false);
  const [reportModalShow, setReportModalShow] = useState(false);

  const dispatch = useDispatch();

  const handleClick = () => {
    setShowModal(true);
    handleCloseUserInfoModal();
  };
  useEffect(() => {
    const urlElements = window.location;
    var url = new URL(urlElements);
    let token = url.searchParams.get('token');

    if (token) {
      setState(true);
      sessionStorage.setItem('name', 'Jyothi Prakash');
    }
    console.log(token, 'token');
  }, []);
  const handleClose = () => {
    setShowModal(false);
    handleCloseUserInfoModal();
  };
  const handleShowUserInfoModal = () => {
    setShowUserInfoModal(true);
  };
  const handleCloseUserInfoModal = () => {
    setShowUserInfoModal(false);
  };

  const openInfoModal = () => {
    setInfo(true);
  };
  const closeInfoModal = () => {
    setInfo(false);
  };
  const logout = () => {
    sessionStorage.clear();
    window.location.href = '/';
  };
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const user_name = sessionStorage.getItem('name')
    ? sessionStorage.getItem('name').split('@')[0]
    : 'Ananymous';
  return (
    <div>
      <nav id='sidebar' className='activexyz'>
        {processorModule ? (
          <ul id='sideNavTop' className='list-unstyled components'>
            {/* <li id='logoLi'>
              <div className='activexyz logo_ind'></div>
            </li> */}
            <Tooltip title='Dashboard' placement='right' id='tooltipContainer'>
              <li>
                <div

                  onClick={() => {
                    dispatch(handleResetSearchTerm());
                    if (pathname === '/editdocument') {
                      dispatch(resetPreProcessing());
                      sessionStorage.setItem('activeIndex', 1);
                      navigate.push({
                        pathname: '/',
                      });
                    } else {
                      sessionStorage.setItem('activeIndex', 0);
                      navigate.push({
                        pathname: '/',
                      });
                    }
                  }}
                  style={{ textDecoration: 'none' }}
                >
                  <div
                    data-tip='dash_document'
                    id='sideElements1'
                    className={
                      splitLocation[1] === 'processordashboard'
                        ? 'activepage'
                        : ''
                    }
                  >
                    <div className='activexyz dash_document'>
                      <p>Dashboard</p>
                    </div>
                  </div>
                </div>
              </li>
            </Tooltip>
          </ul>
        ) : (
          <div>
            <ul id='sideNavTop' className='list-unstyled components'>
              {/* <li id='logoLi'>
                <div className='activexyz logo_ind'></div>
              </li> */}
              <Tooltip title='Queue' placement='right' id='tooltipContainer'>
                <li>
                  <Link
                    onClick={() => {dispatch(handleResetSearchTerm()); sessionStorage.setItem('activeAdmin',0);}}
                    to='/'
                    style={{ textDecoration: 'none' }}
                  >
                    <div
                      data-tip='Queue'
                      id='sideElements1'
                      className={splitLocation[1] === '' || splitLocation[1] === 'queuesMapProcessors' ? 'activepage' : ''}
                    >
                      <div className='activexyz Queue'>
                        <p> Queue </p>
                      </div>
                    </div>
                  </Link>
                </li>
              </Tooltip>
              <Tooltip title='Processor' placement='right'>
                <li>
                  <Link
                    onClick={() => {dispatch(handleResetSearchTerm());sessionStorage.setItem('activeAdmin',1);}}
                    to='/processers'
                    style={{ textDecoration: 'none' }}
                  >
                    <div
                      data-tip='Processor'
                      id='sideElements1'
                      className={
                        splitLocation[1] === 'processers' ? 'activepage' : ''
                      }
                    >
                      <div className='activexyz Processor'>
                        <p> Processor </p>
                      </div>
                    </div>
                  </Link>
                </li>
              </Tooltip>
              <Tooltip title='Document Types' placement='right'>
                <li>
                  <Link
                    onClick={() => {dispatch(handleResetSearchTerm());sessionStorage.setItem('activeAdmin',2);}}
                    to='/documents'
                    style={{ textDecoration: 'none' }}
                  >
                    <div
                      data-tip='Document'
                      id='sideElements1'
                      className={
                        splitLocation[1] === 'documents' ? 'activepage' : ''
                      }
                    >
                      <div className='activexyz Document'>
                        <p> Document </p>
                      </div>
                    </div>
                  </Link>
                </li>
              </Tooltip>
              <Tooltip title='Fields' placement='right'>
                <li>
                  <Link
                    onClick={() => {dispatch(handleResetSearchTerm()); sessionStorage.setItem('activeAdmin',3);}}
                    to='/fields'
                    style={{ textDecoration: 'none' }}
                  >
                    <div
                      data-tip='Fields'
                      id='sideElements1'
                      className={
                        splitLocation[1] === 'fields' ? 'activepage' : ''
                      }
                    >
                      <div className='activexyz Fields'>
                        <p> Fields </p>
                      </div>
                    </div>
                  </Link>
                </li>
              </Tooltip>
              <Tooltip title='Upload ' placement='right' id='tooltipContainer'>
                <li>
                  <Link
                    onClick={() => {dispatch(handleResetSearchTerm());sessionStorage.setItem('activeAdmin',4);}}
                    to='/uploadDashboard'
                    style={{ textDecoration: 'none' }}
                  >
                    <div
                      data-tip='dash_document'
                      id='sideElements1'
                      className={
                        splitLocation[1] === 'uploadDashboard' ? 'activepage' : ''
                      }
                    >
                      <div className='activexyz upload_document'>
                        <p>Dashboard</p>
                      </div>
                    </div>
                  </Link>
                </li>
              </Tooltip>
              <Tooltip title='Test' placement='right' id='tooltipContainer'>
                <li>
                  <Link
                    onClick={() => {dispatch(handleResetSearchTerm());sessionStorage.setItem('activeAdmin',5);}}
                    to='/test'
                    style={{ textDecoration: 'none' }}
                  >
                    <div
                      data-tip='dash_document'
                      id='sideElements1'
                      className={
                        splitLocation[1] === 'test' ? 'activepage' : ''
                      }
                    >
                      <div className='activexyz test'>
                        <p>Test</p>
                      </div>
                    </div>
                  </Link>
                </li>
              </Tooltip>

            </ul>
          </div>
        )}
        <ul id='sideNavBottom' className='list-unstyled components'>
          <li>
            <div onClick={openInfoModal} style={{ cursor: 'pointer' }}>
              <img src={InfoImage} alt='infoimage' />
            </div>
          </li>
          {/* Info modal */}
          <Dialog
            className='custom-dialog-container custom-dialog-container-info'
            onClose={closeInfoModal}
            aria-labelledby='simple-dialog-title'
            open={info}
          >
            <DialogTitle>
              <div
                onClick={() => {
                  setReportModalShow(true);
                  closeInfoModal(false);
                }}
                className='devinfo'
              >
                <img src={devInfo} alt='devinfo' />
                <div>Report a Problem</div>
              </div>
              <div className='devinfo'>
                <img src={devhub} alt='devhub' />
                <div>Developer Hub</div>
              </div>
            </DialogTitle>
          </Dialog>

          <li>
            <div className='Logout' onClick={handleShowUserInfoModal}>
              {user_name[0].toUpperCase()}
              <p> Logout </p>
            </div>
          </li>
          <Dialog
            className='custom-dialog-container'
            onClose={handleCloseUserInfoModal}
            aria-labelledby='simple-dialog-title'
            open={showUserInfoModal}
          >
            <DialogTitle>
              <div className='dialogLogo'>{user_name[0].toUpperCase()}</div>
            </DialogTitle>
            <DialogTitle>{user_name}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {sessionStorage.getItem('name')}
              </DialogContentText>
            </DialogContent>
            <hr
              style={{
                height: '1px',
                width: '100%',
                color: 'black',
                margin: 0,
                padding: 0,
              }}
            />
            <DialogActions>
              <Button
                onClick={handleClick}
                variant='text'
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                Logout
              </Button>
            </DialogActions>
            <div className='dialogFooter'>
              <a href='https://intainft.com/privacy-policy/' target='_blank'>
                Privacy Policy
              </a>
              <a href='https://intainft.com/term-and-conditions/' target='_blank'>
                Terms and Conditions
              </a>
            </div>
          </Dialog>
        </ul>
      </nav>

      {showModal ? (
        <Modal
          handleClick={handleClick}
          handleClose={handleClose}
          name={'Jyothi Prakash'}
          desc={
            'You are logging out of the portal. Are you sure want to proceed? '
          }
          header={'Confirm Logout'}
          modal={showModal}
          logout={logout}
          functionText='Logout'
        />
      ) : (
        ''
      )}
      {reportModalShow && (
        <ReportModal
          handleClick={() => setReportModalShow(true)}
          handleClose={() => setReportModalShow(false)}
          header={'Confirm Logout'}
          modal={reportModalShow}
          open={reportModalShow}
        />
      )}
    </div>
  );
};
export default SideMenu;
